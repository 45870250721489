<template>
    <div class="user-notification">
        <div class="main-h1">Уведомления</div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title">Профиль</div>
                <table class="table">
                    <thead>
                    <tr>
                        <th class="media-only-desktop">Имя</th>
                        <th class="media-only-desktop">При подаче иска</th>
                        <th class="media-only-desktop">При формировании дела</th>
                        <th class="media-only-desktop">При загрузке решения</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in users">
                        <td>
                            <router-link :to="`/panel/user/${item.user_id}/edit`">{{ item.user_name }}</router-link> [{{ item.user_city_title}}] ({{ item.roles.join(', ') }})
                        </td>
                        <td>
                            <input type="checkbox" :checked="item.types.includes(1)"
                                   @change="changeEvent(item.user_id, 1, $event.target.checked)">
                        </td>
                        <td>
                            <input type="checkbox" :checked="item.types.includes(2)"
                                   @change="changeEvent(item.user_id, 2, $event.target.checked)">
                        </td>
                        <td>
                            <input type="checkbox" :checked="item.types.includes(3)"
                                   @change="changeEvent(item.user_id, 3, $event.target.checked)">
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "userView",
    data() {
        return {
            users: [],
        }
    },
    methods: {
        getUsers() {
            this.$api.get(`user/notification`).then(res => {
                if (res.data.success) {
                    this.users = res.data.data
                }
            })
        },
        changeEvent(user_id, type_id, checked) {
            this.$api.post(`user/notification`, {user_id: user_id, type_id: type_id, checked: checked}).then(res => {
                if (res.data.success) {
                    this.$store.commit('showNotify',{title:'Сохранено',content:'Настройки успешно сохранены.',type:'success'});
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        }
    },
    created() {
        this.getUsers();
    }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables";

</style>